import React, { FC } from 'react';

import img from '../../assets/blog-image.jpg';
// markup
const Cover: FC = () => (
  <div className="grid md:grid-cols-2 top-0 grid-rows-1 md:my-6 md:grid-rows-none items-center justify-center text-xl ">
    <div className=" text-center md:w-3/5 m-auto rows-start-1">
      <img alt="About" className="rounded" src={img} />
    </div>
    <div className=" w-3/4 my-12  md:my-0 text-justify m-auto row-start-2 md:row-start-1 md:col-start-2 font-article ">
      <p>
        Born and raised in the South West of France, I love going to the beach
        every summer. I adore video games and reading books and horseback
        riding.
      </p>
      <p className="mt-3">
        My passion for web development begun when I was fifteen. We had to
        create a website for technology class, and I would work on it for hours.
      </p>
      <p className="mt-3">
        Even though I&apos;ve always been passionate about literature, I knew
        this was my thing.
      </p>
      <p className="mt-3">
        I learned many things on my own initiative. I like to discover new
        things and build projects from scratch.
      </p>
    </div>
  </div>
);

export default Cover;
