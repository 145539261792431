import React, { FC } from 'react';
import About from '../components/About';

import App from '../components/App';

// markup
const DisclamerPage: FC = () => (
  <App>
    <About />
  </App>
);

export default DisclamerPage;
